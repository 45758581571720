$additionGreenishColor: #f2fcf8;
$additionClassicGreenColor: #1fb197;
$additionLightGreenColor: #3ea35a;
$additionClassicBlueColor: #99deff;
$additionClassicRedColor: #e34457;
$additionGoldColor: #faad14;
$neutralLightGreyColor: #b0b0b0;
$neutralLightestGreyColor: #e6e6e6;
$additionPurpleColor: #ba55d3;
$bluish02Color: #a0cfed;
$bluish01Color: #cde8f8;

:root {
  // primary
  --primary-classic-blue-light-color: #259aea;
  --primary-classic-blue-color: #107cc7;
  --primary-dark-blue-color: #146094;
  --primary-navy-blue-color: #051e2f;

  // additional
  --addition-classic-green-color: #{$additionClassicGreenColor};
  --addition-dark-green-color: #1f9683;
  --addition-red-color: #ff4d4f;
  --addition-gold-color: #{$additionGoldColor};
  --addition-greenish-color: #{$additionGreenishColor};

  // bluish
  --bluish-menu-active-color: #ebf6fccc;
  --bluish-menu-active-background-color: rgba(235, 246, 252, 0.8);
  --bluish-00-color: #edf7fc;
  --bluish-01-color: #{$bluish01Color};
  --bluish-02-color: #{$bluish02Color};
  --bluish-03-color: #91bae6;
  --bluish-04-color: #4878af;
  --bluish-background-color-start: #dfeff8;
  --bluish-background-color-end: #e4f1fa;
  --bluish-background: linear-gradient(
    90.76deg,
    var(--bluish-background-color-start) 1.12%,
    var(--bluish-background-color-end) 59.1%
  );

  // neutral
  --neutral-white-color: #ffffff;
  --neutral-grey-navy-color: #516578;
  --neutral-label-color: #5c748a;
  --neutral-grey-navy-light-color: #a7b1c2;
  --neutral-stroke-color: #d5dce9;
  --neutral-grey-outline-color: #dfe3e7;
  --neutral-dark-color: #384355;
  --neutral-dark-grey-color: #58595b;
  --neutral-grey-placeholder-color: #8c8c8c;
  --neutral-light-grey-color: #{$neutralLightGreyColor};
  --neutral-lightest-grey-color: #{$neutralLightestGreyColor};
  --neutral-disabled-color: #fafbfc;
  --neutral-grey-2-color: #edeff2;
}
