@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

:root {
  --font-size: 14px;
  --h2-font-size: 1.5715rem;
  --h2-font-weight: 700;
  --h3-font-size: 1.42857rem;
  --h3-font-weight: 700;
  --h4-font-size: 1.142857rem;
  --h4-font-weight: 700;
  --h4-sub-font-size: 1.1428rem;
  --h4-sub-font-weight: 600;
  --small-font-size: 0.875em;
  --line-height: 1.5715;
  --font-color: var(--neutral-dark-color);
}

html,
body {
  font-size: var(--font-size);
  line-height: var(--line-height);
  font-family: 'Open Sans', sans-serif;
  color: var(--font-color);
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  &,
  &.ant-typography {
    color: var(--primary-navy-blue-color);
  }
}

h2,
.h2,
h2.ant-typography {
  font-weight: var(--h2-font-weight);
  font-size: var(--h2-font-size);
}

h3,
.h3,
h3.ant-typography {
  font-weight: var(--h3-font-weight);
  font-size: var(--h3-font-size);
}

h4,
.h4,
h4.ant-typography {
  font-size: var(--h4-font-size);
  font-weight: var(--h4-font-weight);
}

h4.sub,
.h4.sub,
h4.ant-typography.sub {
  font-weight: var(--h4-sub-font-weight);
  font-size: var(--h4-sub-font-size);
}

small,
.text-small {
  font-size: var(--small-font-size);
}

.text-help {
  color: var(--text-color-help);
}

.text-error {
  color: var(--text-color-error) !important;
}

.text-multiline {
  display: block;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: right;
}

.text-start {
  text-align: left;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.disabled-anchor-container {
  cursor: text;
}

a {
  color: var(--ant-link-color);

  &:hover {
    color: var(--ant-link-color-hover);
  }

  &:active,
  &:focus {
    color: var(--ant-link-color-active);
  }

  &[disabled],
  &.disabled {
    color: var(--ant-link-color-disabled);
    pointer-events: none;
  }
}
