$bottomSpaceAreaHeight: 350px;

.paths:global(.ant-breadcrumb) {
  padding-bottom: 20px;
}

.card + .tabCard,
.card + .card,
.tabCard + .tabCard,
.tabCrad + .card {
  margin-top: 40px;
}

.card {
  &:global(.no-body) {
    & > :global(.ant-card-head) > :global(.ant-card-head-wrapper) > :global(.ant-card-head-title),
    & > :global(.ant-card-head) > :global(.ant-card-head-wrapper) > :global(.ant-card-extra) {
      padding-bottom: 2rem;
    }

    :global(.ant-card-body) {
      display: none;
    }
  }

  & > :global(.ant-card-head) {
    border-bottom: 0;

    & > :global(.ant-card-head-wrapper) > :global(.ant-card-head-title),
    & > :global(.ant-card-head-wrapper) > :global(.ant-card-extra) {
      padding-bottom: 0;
      padding-top: 2rem;
      display: inline-flex;
      align-items: center;
    }
  }
}

.tabCard > :global(.ant-tabs.ant-tabs-top) {
  & > :global(.ant-tabs-nav) {
    margin-bottom: 0;
    padding-left: 2rem;
  }

  & > :global(.ant-tabs-content-holder) {
    // card styles

    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 12px rgba(16, 124, 199, 0.06), 0px 6px 30px rgba(16, 124, 199, 0.08);
    border-radius: 12px;
    padding: 2rem;
  }
}

.page {
  padding: 1rem;
  padding-top: 20px;
  position: relative;
  margin-bottom: $bottomSpaceAreaHeight;
}

.body {
  margin-left: 0;
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
