.title {
  margin-bottom: 0rem;
}

.container {
  margin-bottom: 1rem;

  &:global(.level-0),
  &:global(.level-2) {
    --title-font-size: var(--h4-sub-font-size);
    --title-font-weight: var(--h4-sub-font-weight);
  }

  &:global(.level-1) {
    --title-font-size: var(--h3-font-size);
    --title-font-weight: var(--h3-font-weight);
  }
}

h1.title:global(.ant-typography),
h2.title:global(.ant-typography),
h3.title:global(.ant-typography),
h4.title:global(.ant-typography) {
  margin-bottom: 0rem;
  font-style: normal;
  font-weight: var(--title-font-weight);
  font-size: var(--title-font-size);
}
