:root {
  --ant-primary-color: var(--primary-classic-blue-color);
  --ant-primary-color-hover: var(--primary-classic-blue-light-color);
  --ant-primary-color-active: var(--primary-dark-blue-color);
  --ant-error-color: var(--addition-red-color);
  --ant-primary-5: var(--primary-classic-blue-light-color);

  --ant-success-color: var(--addition-classic-green-color);
  --ant-success-color-deprecated-bg: var(--addition-greenish-color);
  --ant-success-color-deprecated-border: rgba(31, 177, 151, 0.5);

  --ant-info-color: var(--primary-classic-blue-color);
  --ant-info-color-deprecated-bg: var(--bluish-00-color);
  --ant-info-color-deprecated-border: rgba(37, 154, 234, 0.5);

  --ant-warning-color: var(--addition-gold-color);
  --ant-warning-color-deprecated-bg: #fff6e3;
  --ant-warning-color-deprecated-border: rgba(250, 173, 20, 0.5);

  --ant-error-color: var(--addition-red-color);
  --ant-error-color-deprecated-bg: #ffeeee;
  --ant-error-color-deprecated-border: rgba(255, 77, 79, 0.5);

  // ANT CUSTOM
  --ant-primary-color-disabled-border: var(--neutral-grey-outline-color);
  --ant-primary-color-disabled: var(--neutral-disabled-color);
  --ant-primary-color-disabled-text: var(--neutral-grey-outline-color);

  --ant-default-color: var(--primary-classic-blue-color);
  --ant-default-color-hover: var(--primary-classic-blue-light-color);
  --ant-default-background-color-hover: rgba(235, 246, 252, 0.8);
  --ant-default-color-active: var(--primary-classic-blue-color);
  --ant-default-color-disabled: var(--neutral-grey-outline-color);
  --ant-default-background-color-disabled: var(--neutral-disabled-color);

  --ant-link-color: var(--primary-classic-blue-color);
  --ant-link-color-hover: var(--primary-dark-blue-color);
  --ant-link-color-active: var(--primary-dark-blue-color);
  --ant-link-color-disabled: var(--neutral-light-grey-color);

  --ant-menu-link-color: var(--primary-dark-blue-color);
  --ant-menu-link-color-hover: var(--ant-primary-color-hover);
  --ant-menu-link-color-active: var(--addition-classic-green-color);
  --ant-menu-link-bar-color-active: var(--addition-dark-green-color);
  --ant-menu-submenu-item-background-color-active: var(
    --addition-greenish-color
  );
  --ant-menu-submenu-item-background-color-hover: var(--bluish-00-color);

  --ant-breadcrumb-color: var(--ant-link-color-active);
  --ant-breadcrumb-color-hover: var(--ant-link-color-hover);
  --ant-breadcrumb-color-active: var(--neutral-grey-navy-color);
  --ant-breadcrumb-color-disabled: var(--neutral-grey-navy-color);

  --ant-table-tr-color-hover: rgba(235, 246, 252, 0.6);
  --ant-table-th-color-hover: rgba(235, 246, 252, 0.6);
  --ant-table-th-color: var(--neutral-heading-color);
  --ant-table-th-background-color: white;
  --ant-table-th-background-color-sorted: var(--neutral-disabled-color);
  --ant-table-td-background-color-sorted: var(--neutral-disabled-color);
  --ant-table-th-icon-color: var(--neutral-stroke-color);
  --ant-table-th-icon-color-active: var(--ant-menu-link-color-active);

  --ant-label-color: var(--neutral-label-color);
  --ant-input-color-disabled: var(--font-color);
  --ant-input-background-color-disabled: var(--neutral-disabled-color);
  --ant-input-border-color: var(--neutral-stroke-color);
  --ant-input-border-color-disabled: var(--neutral-stroke-color);
  --ant-input-border-radius: 4px;

  --ant-tree-node-background-color-hover: var(--bluish-menu-active-color);
  --ant-tree-node-background-color-selected: var(--addition-greenish-color);
  --ant-tree-node-color: var(--primary-dark-blue-color);
  --ant-tree-node-color-hover: var(--primary-classic-blue-color);
  --ant-tree-node-color-selected: var(--addition-dark-green-color);

  --ant-select-menu-item-color: var(--neutral-grey-navy-color);
  --ant-select-menu-item-color-active: var(--ant-select-menu-item-color);
  --ant-select-menu-item-background-color-active: var(
    --bluish-menu-active-color
  );
  --ant-select-menu-item-color-selected: var(--neutral-dark-color);
  --ant-select-menu-item-background-color-selected: var(
    --addition-greenish-color
  );
  --ant-select-menu-item-check-color: var(--addition-classic-green-color);

  --ant-card-actions-split-color: var(--neutral-grey-2-color);

  --ant-pagination-link-color: var(--neutral-grey-navy-color);
  --ant-pagination-link-border-color: var(--neutral-stroke-color);
  --ant-pagination-link-background-color: white;
  --ant-pagination-link-color-hover: var(--neutral-grey-navy-color);
  --ant-pagination-link-border-color-hover: var(--bluish-01-color);
  --ant-pagination-link-background-color-hover: var(--bluish-menu-active-color);
  --ant-pagination-link-color-active: var(--addition-classic-green-color);
  --ant-pagination-link-border-color-active: var(
    --addition-classic-green-color
  );
  --ant-pagination-link-background-color-active: var(--addition-greenish-color);
  --ant-pagination-link-color-disabled: var(--neutral-light-grey-color);
  --ant-pagination-link-border-color-disabled: var(
    --neutral-grey-outline-color
  );
  --ant-pagination-link-background-color-disabled: var(
    --neutral-disabled-color
  );

  --ant-checkbox-border-color: var(--neutral-grey-navy-light-color);
  --ant-checkbox-color-active: var(--primary-classic-blue-light-color);
  --ant-checkbox-border-color-active: var(--primary-classic-blue-light-color);
  --ant-checkbox-outline-color-hover: #{rgba(#a7b1c2, 0.2)};
  --ant-checkbox-color-disabled: var(--neutral-stroke-color);
  --ant-checkbox-border-color-disabled: var(--neutral-lightest-grey-color);
  --ant-checkbox-background-color-disabled: #eff7fd;
}

:root {
  --ant-menu-link-bar-width: 4px;
}
