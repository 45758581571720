.container {
  position: relative;
}

.count {
  position: absolute;
  top: 1rem;
  right: -0.4rem;
  background-color: var(--addition-classic-green-color);
  border-radius: 50%;
  font-size: 0.8rem;
  line-height: 1.3rem;
  width: 1.3rem;
  text-align: center;
  color: white;
}
