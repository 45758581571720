.fade {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  background-color: rgba(#000, 0.1);
}

.container {
  background-color: white;
  text-align: center;
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid #fefefe;
}
