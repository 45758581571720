.container {
  width: 450px;
}

.list {
  max-height: 500px;
  overflow-y: auto;
}

.buttons {
  display: flex;
  justify-content: space-around;
  font-size: small;
  padding: 2;
}
