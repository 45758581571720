$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$sides: (
  x: left right,
  y: top bottom,
  s: left,
  e: right,
  t: top,
  b: bottom
) !default;

@each $key, $value in $spacers {
  @each $side, $properties in $sides {
    .p#{$side}-#{$key} {
      @each $property in $properties {
        padding-#{$property}: $value !important;
      }
    }

    .m#{$side}-#{$key} {
      @each $property in $properties {
        margin-#{$property}: $value !important;
      }
    }

    .p-#{$key} {
      padding: #{$value} !important;
    }

    .m-#{$key} {
      padding: #{$value} !important;
    }
  }
}