.container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-align: center;
}

.pickerInput {
  position: absolute;
  transform: translateX(-100000px);
}

.cropperContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.cropperActions {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  text-align: right;
  background-color: rgba(0, 0, 0, 0.1);

  & > :global(.anticon) {
    padding: 12px;
  }

  & > :global(.anticon) > svg {
    color: white;
    cursor: pointer;
    font-size: 18px;
  }

  & > :global(.anticon) + :global(.anticon) {
    margin-left: 12px;
  }
}

:global(.ReactCrop).cropperCrop {
  display: block;
  margin: auto;
  max-width: 80%;
  max-height: 80vh;

  :global(.ReactCrop__image) {
    max-height: 80vh;
  }
}
