.container {
  display: flex;
  flex-flow: row nowrap;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.label {
  display: block;
  flex: 0 0 none;
  max-width: 170px;
  width: 30%;

  padding-right: 1rem;
  color: var(--neutral-label-color);

  &::after {
    content: ':';
  }
}

.value {
  padding-left: 1rem;
  display: block;
  flex: 0 0 none;
  width: 70%;
  max-width: 600px;
  overflow-wrap: break-word;
}
