$headerHeight: 57px;
$footerHeight: 65px;

.layout:global(.ant-layout) {
  --header-height: #{$headerHeight};
  --footer-height: #{$footerHeight};
}

.main {
  flex: 1 0 auto !important;
  margin-top: $headerHeight;
}

.header:global(.ant-layout-header) {
  background: white !important;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: $headerHeight;
  line-height: $headerHeight;
  box-shadow: 0px 2px 6px rgba(20, 96, 148, 0.1);

  :global(.ant-menu) {
    height: $headerHeight;

    :global(.user-actions) :global(.ant-menu-submenu-title) {
      :global(.ant-menu-title-content),
      :global(.ant-menu-submenu-arrow) {
        display: none;
      }
    }
  }

  svg {
    font-size: 1.71rem;
    vertical-align: middle;
  }
}

.footer {
  &:global(.ant-layout-footer) {
    height: $footerHeight;
    background-color: transparent;
    border-top: none;
    color: var(--text-color-help);
    font-size: 0.75em;
  }
}

.brand {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: monospace;
  color: var(--primary-dark-blue-color);
}
