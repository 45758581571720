@import '../../assets/styles/colors.scss';

$borderColor: #ddd;
$paddingX: 1.7142857rem;
$paddingY: 1.142857rem;
$fontColor: rgba(0, 0, 0, 0.75);
$footerHeight: 60px;

.createButton:global(.ant-btn:not(.ant-btn-link):not(.ant-btn-sm)) {
  position: fixed;
  right: 7%;
  bottom: 13%;
  border-radius: 50% !important;
  min-width: unset !important;
  width: 5rem !important;
  height: 5rem !important;
  font-size: 2rem !important;
  line-height: 5rem !important;
  padding: 0 !important;
  font-weight: 300;
  z-index: 3;
}

.calendar {
  background-color: white;
  border-radius: 4px;
  border-top: 1px solid $borderColor;

  /* Hide bar under calendar view selection */
  :global(.ant-menu::after),
  :global(.ant-menu .ant-menu-item::after) {
    display: none !important;
  }

  :global(.rbc-header) {
    border-bottom: none;
    padding: 4px;
  }

  :global(.rbc-header.rbc-today) {
    background: none;
  }

  :global(.rbc-header.rbc-today .rbc-button-link) {
    background: rgba($color: $bluish01Color, $alpha: 0.3);
    border-radius: 20px;
  }

  :global(.rbc-day-slot.rbc-today) {
    background: rgba($color: $bluish01Color, $alpha: 0.3);
  }

  :global(.rbc-button-link) {
    width: 100%;
    height: 100%;
  }

  :global(.rbc-time-content) {
    border-top: 1px solid $borderColor;
  }

  :global(.rbc-timeslot-group) {
    min-height: 70px;
    :global(.rbc-time-slot) {
      border-top: none;
    }
  }

  :global(.rbc-time-gutter.rbc-time-column) {
    :nth-of-type(1) {
      padding-top: 6px;
    }
    :global(.rbc-timeslot-group) {
      border-bottom: none;
    }
  }

  :global(.rbc-day-slot .rbc-events-container) {
    margin-right: 1px;
  }

  :global(.rbc-time-header) :global(.rbc-time-header-content) :global(.rbc-time-header-cell) {
    min-height: 90px;
  }

  &:not(:global(.--has-overnight-event)) {
    :global(.rbc-allday-cell) {
      display: none;
    }
  }

  :global(.rbc-event) {
    padding: 0;
    border: none !important;
    border-radius: 0;
    background: $bluish01Color;
    color: inherit;
  }
}

.footer {
  height: $footerHeight;
  padding: 0.3rem 1rem;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
}

.event {
  padding: 1px;
  z-index: 1;
  overflow-y: hidden;

  &.time {
    width: 100%;
    height: 100%;

    &:hover {
      z-index: 2;
    }
  }

  .content {
    font-size: 0.85rem;
    height: 100%;
    width: 100%;
    padding: 8px;
    overflow: hidden;
    border: 1px solid $bluish02Color;
    border-radius: 2px;
    overflow-y: auto;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    .name {
      font-weight: 600;
      gap: 0 !important;
    }

    .time {
      font-weight: 400px;
      padding-top: 5px;
    }
  }
}

.timeHeaderCell {
  color: $fontColor;

  .weekday {
    padding-top: 1em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: -1em;
  }

  .date {
    line-height: 2em;
    font-size: var(--h2-font-size);
    font-weight: var(--h2-font-weight);
  }

  &:global(.--today) {
    border-radius: 8px;
    background-color: var(--addition-blue-color);
    color: var(--primary-blue-color);
  }
}

.timeslot {
  padding-left: 3rem;
  padding-right: 3rem;
  font-weight: 600;

  margin-top: -1.1rem;

  .time {
    padding-right: 0.3em;
  }

  .amPm {
    color: var(--neutral-light-grey-color);
  }

  & + :global(.rbc-time-slot) {
    display: none;
  }
}

.toolbar {
  padding: $paddingY $paddingX;
  border-right: 1px solid $borderColor;
  border-left: 1px solid $borderColor;

  .today {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px !important;
  }

  .range {
    line-height: 1.3em;

    .navigation :global(.anticon) {
      color: var(--ant-primary-color);
      font-size: 1.3em !important;
    }

    .label {
      font-size: var(--h4-font-size);
      font-weight: var(--h4-font-weight);
    }
  }

  :global(.ant-menu-horizontal) {
    border-bottom: none !important;
  }
}
